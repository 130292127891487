<ng-container [ngSwitch]="isLoggedIn">
  <ng-container *ngSwitchCase="true">
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="py-4">
            <app-nav></app-nav>
          </div>
        </div>
        <div class="col-10">
          <div class="my-4">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <router-outlet></router-outlet>
  </ng-container>
</ng-container>
