import {EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  errorReceived = new EventEmitter<string>();
  successReceived = new EventEmitter<string>();
  infoReceived = new EventEmitter<string>();

  constructor() { }

  logError(error: any) {
    if (error instanceof Error) {
      console.log(error.stack != null ? error.stack.toString() : error.message);
      this.errorReceived.emit(error.message);
    } else {
      this.errorReceived.emit(error);
    }
  }

  logSuccess(message: string) {
    console.log(message);
    this.successReceived.emit(message);
  }

  logInfo(message: string) {
    console.log(message);
    this.infoReceived.emit(message);
  }
}
