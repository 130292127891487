import {Injectable} from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/auth';
import {User} from './models/user';
import {ApiService} from './api.service';
import {LoggingService} from './logging.service';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: User;

  constructor(private apiService: ApiService, private loggingService: LoggingService, private router: Router) {
  }

  public login(email: string, password: string): void {
    firebase.auth().signInWithEmailAndPassword(email, password).catch(err => {
      this.loggingService.logError(err);
    });
  }

  async onAuthStateChanged(user: firebase.User): Promise<void> {
    this.user = await this.apiService.getUser();
    if (user) {
      this.router.navigate(['/']);
    } else {
      this.router.navigate(['/auth']);
    }
  }
}
