import {Component, enableProdMode, isDevMode} from '@angular/core';
import {LoggingService} from './logging.service';
import {Router} from '@angular/router';
import {AuthService} from './auth.service';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import {environment} from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  public isLoggedIn = false;

  constructor(private routes: Router, private loggingService: LoggingService, private authService: AuthService) {
    try {

      firebase.initializeApp(environment.firebaseConfig);

      if (!isDevMode()) {
        enableProdMode();
        firebase.analytics();
        this.loggingService.logInfo('prod mode');
      } else {
        this.loggingService.logInfo('dev mode');
      }

      this.loggingService.logInfo('firebase configured');
      firebase.auth().onAuthStateChanged((user) => {
        this.isLoggedIn = user !== null;
        this.authService.onAuthStateChanged(user);
      });

    } catch (e) {
      this.loggingService.logError(e);
    }

  }
}
