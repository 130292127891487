import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {OverviewComponent} from './overview/overview.component';
import {AuthComponent} from './auth/auth.component';
import {UsersComponent} from './users/users.component';
import {LogoutComponent} from './logout/logout.component';
import { AngularFireAuthGuard } from '@angular/fire/auth-guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'overview',
  },
  {
    path: 'overview',
    component: OverviewComponent,
    canActivate: [AngularFireAuthGuard]
  },
  {
    path: 'users',
    component: UsersComponent,
    canActivate: [AngularFireAuthGuard]
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'auth',
    component: AuthComponent
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
