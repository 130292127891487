import {EventEmitter, Injectable, isDevMode} from '@angular/core';
import axios from 'axios';
import {User} from './models/user';
import firebase from 'firebase/app';
import 'firebase/auth';
import * as admin from 'firebase-admin';
import {LoggingService} from './logging.service';
import {Router} from '@angular/router';
import * as clonedeep from 'lodash.clonedeep';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl = isDevMode() ? 'http://localhost:5005/' : 'https://api.aigrowsystems.com/';
  private user: User;
  userChanged = new EventEmitter<User>();

  constructor(private loggingService: LoggingService, private router: Router) {
  }

  async GetAsync(endpoint: string, params: any, token: string, contentType = 'text/plain'): Promise<any> {
    try {
      if (this.user == null) {
        this.loggingService.logInfo('waiting on profile initialization for endpoint call: ' + endpoint);
        return;
      }
      const url = `${this.baseUrl}app/` + endpoint;
      const options = {
        headers: {
          authorization: 'Bearer ' + token,
          contentType,
        },
        params: params ?? {}
      };

      const data = (await axios.get(url, options)).data;
      if (data instanceof String && data.includes('Error Authorizing You...')) {
        this.loggingService.logError(data);
        return null;
      }
      this.loggingService.logInfo('hitting server: ' + endpoint);
      return data;
    } catch (e) {
      this.loggingService.logError(e);
    }
  }

  updateUser(user: User, callback): void {
    firebase.auth().currentUser.updateProfile({
      displayName: user.name,
    }).then(() => {
      this.user = user;
      this.loggingService.logInfo('hitting firebase update user');
      callback(null);
    }).catch(e => callback(e.message));
  }

  async getUser(): Promise<User> {
    if (this.user == null) {
      await this.setupUser();
    }
    return clonedeep(this.user);
  }

  private async setupUser(): Promise<User> {
    if (this.user == null) {
      const fbUser = firebase.auth().currentUser;
      if (fbUser) {
        this.user = {
          id: fbUser.uid,
          name: fbUser.displayName,
          email: fbUser.email
        };
      }
    }
    this.userChanged.emit(this.user);
    return this.user;
  }


  logout(): void {
    firebase.auth().signOut().then(async () => {
      this.user = null;
      await this.setupUser();
      this.router.navigate(['/']);
    });
  }

  async getUsers(): Promise<User[]> {
    await this.GetAsync('seed', {}, await firebase.auth().currentUser.getIdToken());
    const users = [];
    const result = [];
    console.log(result);
    return users;
  }
}

