<div class="container">
  <h1 class="text-center py-5">AI Grow Systems Dashboard</h1>
  <div class="row">
    <div class="col-6 mx-auto">
      <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)">
        <div class="form-group">
          <label for="email">E-Mail</label>
          <input type="email" id="email" class="form-control" name="email" ngModel required email>
        </div>
        <div class="form-group">
          <label for="password">Password</label>
          <input type="password" id="password" class="form-control" name="password" ngModel required>
        </div>
        <button class="btn btn-primary btn-block" type="submit">Login</button>
      </form>
    </div>
  </div>
</div>
