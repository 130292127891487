<table class="table table-hover">
  <thead>
  <tr>
    <th scope="col">Email</th>
    <th scope="col">Name</th>
    <th scope="col">Role</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let user of users">
    <td>{{user.email}}</td>
    <td>{{user.name}}</td>
    <td>{{user.role}}</td>
  </tr>
  </tbody>
</table>
