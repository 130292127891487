import {BrowserModule} from '@angular/platform-browser';
import {isDevMode, NgModule} from '@angular/core';

import { AngularFireAuthGuardModule } from '@angular/fire/auth-guard';


import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {OverviewComponent} from './overview/overview.component';
import {AuthComponent} from './auth/auth.component';
import {FormsModule} from '@angular/forms';
import {UsersComponent} from './users/users.component';
import {NavComponent} from './nav/nav.component';
import {LogoutComponent} from './logout/logout.component';
import {AngularFireModule} from '@angular/fire';
import {environment} from '../environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    LogoutComponent,
    OverviewComponent,
    AuthComponent,
    UsersComponent,
    NavComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireAuthGuardModule
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
